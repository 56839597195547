import { configureStore } from "@reduxjs/toolkit";
import accountProfileReducer from "store/accountProfileSlice";
import apiKeysReducer from "store/apiKeysSlice";
import atsJobsReducer from "store/atsJobsSlice";
import documentsReducer from "store/documentsSlice";
import earningReducer from "store/earningsSlice";
import followReducer from "store/followSlice";
import interviewsReducer from "store/interviewsSlice";
import invitesReducer from "store/invitesSlice";
import jobsReducer from "store/jobsSlice";
import notificationsReducer from "store/notificationsSlice";
import orgMemberReducer from "store/orgMemberSlice";
import orgRolesAndLimitsReducer from "store/orgRolesAndLimitsSlice";
import postIndexReducer from "store/postIndexSlice";
import postsReducer from "store/postsSlice";
import practiceRoundReducer from "store/practiceRoundSlice";
import recruitingTeamReducer from "store/recruitingTeamSlice";
import resumeFeedbackReducer from "store/resumeFeedbackSlice";
import sysAdminReducer from "store/sysAdminSlice";

import { AccountProfile } from "apis/accountProfile";
import { ApiKey } from "apis/apiKey";
import { IntegrationJob } from "apis/atsIntegration";
import { Document } from "apis/document";
import { Earning } from "apis/earningTransaction";
import { UserJobOverview, UserJobs } from "apis/jobs";
import { Notification } from "apis/notifications";
import { OrgMembers, OrgRolesAndLimits } from "apis/orgs";
import { Post } from "apis/posts";
import { InterviewOverview } from "apis/recruit";
import {
  RecruitPlan,
  RecruitingTeam,
  RecruitingTeamMembers,
} from "apis/recruitingTeam";
import { ResumeFeedback } from "apis/resumeFeedback";

import { sysAdminRoles } from "./sysAdminSlice";

export type Action<T> = {
  payload: T;
  type: string;
};

export type State = {
  atsJobs: { value?: IntegrationJob[] };
  apiKeys: { value?: ApiKey[] };
  documents: { value?: Document[] };
  earning: { value: Earning };
  interviews: { counters: InterviewOverview | undefined };
  invites: { value: { sentCount?: number } };
  posts: { value: Post[] };
  practiceRound: { value: { seenCount?: number } };
  postIndex: { value: Record<string, Post> };
  accountProfile: {
    accountProfile: AccountProfile;
    isOnboarded: { pro: boolean; recruit: boolean } | undefined;
  };
  jobs: {
    value: UserJobOverview & UserJobs;
    pageNumber: {
      save: number;
      view: number;
      apply: number;
    };
  };
  notifications: { value: Notification[]; isLoaded: boolean };
  orgMembers: { value: OrgMembers };
  orgRolesAndLimits: { value: Record<string, OrgRolesAndLimits> };
  followedUserIds: { value: string[] };
  recruitingTeam: {
    team?: RecruitingTeam;
    members?: RecruitingTeamMembers;
    plan?: RecruitPlan | null;
  };
  resumeFeedback: { counts: { seenCount?: number }; latest?: ResumeFeedback };
  sysAdminRoles: { value: sysAdminRoles[] };
};

export default configureStore({
  reducer: {
    atsJobs: atsJobsReducer,
    apiKeys: apiKeysReducer,
    documents: documentsReducer,
    earning: earningReducer,
    interviews: interviewsReducer,
    invites: invitesReducer,
    posts: postsReducer,
    postIndex: postIndexReducer,
    practiceRound: practiceRoundReducer,
    accountProfile: accountProfileReducer,
    jobs: jobsReducer,
    notifications: notificationsReducer,
    orgMembers: orgMemberReducer,
    orgRolesAndLimits: orgRolesAndLimitsReducer,
    followedUserIds: followReducer,
    recruitingTeam: recruitingTeamReducer,
    resumeFeedback: resumeFeedbackReducer,
    sysAdminRoles: sysAdminReducer,
  },
});
