import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PostgrestError } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";
import {
  addPlan,
  addTeam,
  addTeamMembers,
  removeTeamMember as removeTeamMemberAction,
  selectRecruitPlan,
  selectRecruitingTeam,
  selectRecruitingTeamMembers,
} from "store/recruitingTeamSlice";
import store from "store/store";

import { useAccount } from "contexts/AccountContext";

import { useRealtimeChannelHealth } from "utils/realtime";

import { fetchAuthWrapper } from "./backend";
import { supabase } from "./supabaseClient";
import { UserInfo } from "./user";

export type RecruitingTeam = {
  id: string;
  teamMembersCount: number;
  pendingInvitationsCount: number;
  name: string;
  ownerId: string;
  isOwner: boolean;
  isSoloTeam: boolean;
  logo?: string;
};

export type RecruitingTeamMembers = {
  id: string;
  teamMembers: (Pick<UserInfo, "fullName" | "avatarUrl" | "shape" | "id"> & {
    isOwner: boolean;
  })[];
  pendingInvitations?: { email: string }[];
};

export type RecruitPlanName = "Starter" | "Growth" | "Business" | "Enterprise";

export type RecruitPlan = RecruitPrice & {
  couponDurationInMonths?: number;
  couponAmountOff?: number;
  couponPercentOff?: number;
  trialEnd?: number;
  cancelAt?: number;
  hasPaymentMethod: boolean;
  interviewUsageForCurrentInterval: number;
};

export type RecruitPrice = {
  planName: RecruitPlanName;
  teamSeats: number;
  interviewFlowsCap: number;
  hasTalentPoolAccess: boolean;
  hasIntegrationsAccess: boolean;
  hasVideoInterviewsAccess: boolean;
  hasWhiteLabelAccess: boolean;
  voicesIncluded: string[];
  usageBasedUnitAmountDecimal: number;
  usageBasedRecurringInterval: string;
  includedInterviewsPerRecurringInterval: number;
  planUnitAmountDecimal: number;
  planRecurringInterval: string;
  trialPeriodDays: number;
};

type RecruitPriceDb = {
  plan_name: RecruitPlanName;
  team_seats: number;
  interview_flows_cap?: number;
  has_talent_pool_access: boolean;
  has_integrations_access: boolean;
  has_video_interviews_access: boolean;
  has_white_label_access: boolean;
  voices_included: string[];
  usage_based_unit_amount_decimal: number;
  usage_based_recurring_interval: string;
  included_interviews_per_recurring_interval: number;
  plan_unit_amount_decimal: number;
  plan_recurring_interval: string;
  trial_period_days: number;
};

const getRecruiterTeam = async (): Promise<RecruitingTeam | undefined> => {
  const { data, error } = await supabase.rpc("get_recruiter_team");

  if (error || !data[0]) {
    logger(error?.message || "No recruiter team found", "error");
    throw new Error(error?.message || "No recruiter team found");
  }

  const team = {
    id: data[0].team_id,
    pendingInvitationsCount: data[0].pending_invitations_count,
    teamMembersCount: data[0].team_members_count,
    isSoloTeam:
      data[0].pending_invitations_count < 1 && data[0].team_members_count < 2,
    name: data[0].team_name,
    ownerId: data[0].team_owner,
    isOwner: data[0].is_owner,
    logo: data[0].logo,
  };

  store.dispatch(addTeam(team));

  return team;
};

export const getTeamMembers = async (): Promise<RecruitingTeamMembers> => {
  const { data, error } = await supabase.rpc("get_recruiter_team_member");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
  if (!data[0]) {
    throw new Error("No recruiting team found for this user");
  }

  const teamMembers = {
    id: data[0].team_id,
    pendingInvitations: data[0].pending_invitations,
    teamMembers: data[0].team_members.map(
      (team_member: {
        id: string;
        avatar_url?: string;
        full_name: string;
        shape: string;
        is_owner: boolean;
      }) => ({
        id: team_member.id,
        avatarUrl: team_member.avatar_url,
        fullName: team_member.full_name,
        shape: team_member.shape,
        isOwner: team_member.is_owner,
      })
    ),
  };
  store.dispatch(addTeamMembers(teamMembers));
  return teamMembers;
};

type TeamLoaded = {
  team: RecruitingTeam;
  isLoaded: true;
  refetch: () => Promise<RecruitingTeam | undefined>;
};

type TeamLoading = {
  team: undefined;
  isLoaded: false;
  refetch: () => Promise<RecruitingTeam | undefined>;
};

export const useRecruiterTeam = (): TeamLoaded | TeamLoading => {
  const team = useSelector(selectRecruitingTeam);
  const { accountProfile } = useAccount(false);

  useEffect(() => {
    if (!accountProfile?.id) return;

    // if we have queried it already, return
    if (team !== undefined) return;

    // once resolved, this method will update the store with the correct team
    getRecruiterTeam();
  }, [team, accountProfile]);

  return team !== undefined
    ? { isLoaded: true, team, refetch: getRecruiterTeam }
    : { isLoaded: false, team: undefined, refetch: getRecruiterTeam };
};

type TeamMembersLoaded = {
  members: RecruitingTeamMembers;
  isLoaded: true;
  refetch: () => Promise<RecruitingTeamMembers | undefined>;
};

type TeamMembersLoading = {
  members: undefined;
  isLoaded: false;
  refetch: () => Promise<RecruitingTeamMembers | undefined>;
};

export const useRecruiterTeamMembers = ():
  | TeamMembersLoaded
  | TeamMembersLoading => {
  const members = useSelector(selectRecruitingTeamMembers);

  useEffect(() => {
    // if we have queried it already, return
    if (members !== undefined) return;
    const get = async () => {
      // once resolved, this method will update the store with the correct members
      await getTeamMembers();
    };
    get();
  }, [members]);

  return members !== undefined
    ? { isLoaded: true, members, refetch: getTeamMembers }
    : { isLoaded: false, members: undefined, refetch: getTeamMembers };
};

export const acceptInvitation = async (
  teamId: string
): Promise<{ error?: string }> => {
  const res = await fetchAuthWrapper.post(
    "/be-api/respond-to-invitation-to-join-recruiting-team",
    { teamId }
  );

  if (res.status !== 200) {
    logger("Error respond-to-invitation-to-join-recruiting-team", "error");
    return { error: "Something went wrong when accepting invitation" };
  }

  const { body } = await res.json();

  return { error: body.error };
};

export const updateTeamInfo = async ({
  id,
  name,
  logo,
}: {
  id: string;
  name: string;
  logo?: string;
}): Promise<{ error: PostgrestError | null }> => {
  const { error } = await supabase
    .from("recruiting_team")
    .update({ team_name: name, logo })
    .eq("id", id);

  if (error) logger(error.message, "error");
  return { error };
};

export const sendInvitation = async (
  emails: string[]
): Promise<{ error?: string }> => {
  const res = await fetchAuthWrapper.post(
    "/be-api/send-invitation-to-join-recruiting-team",
    { invitedMembersEmails: emails }
  );

  if (res.status !== 200) {
    logger("Error send-invitation-to-join-recruiting-team", "error");
    return { error: "Something went wrong when accepting invitation" };
  }

  return {};
};

export const deletePendingInvitation = async ({
  email,
  teamId,
}: {
  email: string;
  teamId: string;
}): Promise<{ error?: string }> => {
  const { data, error } = await supabase
    .from("recruiting_team_invitation")
    .delete()
    .eq("invitee_email", email)
    .eq("team_id", teamId)
    .select();

  if (error) {
    logger(error.message, "error");
    return { error: "Something went wrong when deleting invitation" };
  }

  if (!data || data.length === 0) {
    return { error: "No invitation found" };
  }

  return {};
};

export const removeTeamMember = async (
  memberIdToRemove: string
): Promise<void> => {
  store.dispatch(removeTeamMemberAction(memberIdToRemove));
  const res = await fetchAuthWrapper.post(
    "/be-api/exclude-from-recruiting-team",
    { memberIdToRemove }
  );

  if (res.status !== 200) {
    logger("Error exclude-from-recruiting-team", "error");
    throw new Error("Something went wrong when excluding recruiter from team");
  }
};

export const leaveTeam = async (): Promise<void> => {
  const res = await fetchAuthWrapper.post("/be-api/leave-recruiting-team", {});

  if (res.status !== 200) {
    logger("Error leave-recruiting-team", "error");
    throw new Error("Something went wrong when leaving the team");
  }
};

export const deleteTeam = async (): Promise<void> => {
  const res = await fetchAuthWrapper.post("/be-api/delete-recruiting-team", {});

  if (res.status !== 200) {
    logger("Error delete-recruiting-team", "error");
    throw new Error("Something went wrong when deleting the team");
  }
};

const getRecruitCatalogPrices = async (): Promise<RecruitPrice[]> => {
  const res = await fetchAuthWrapper.get("/be-api/get-recruit-catalog-pricing");
  if (res.status !== 200) {
    logger("Error calling get-recruiter-catalog-pricing", "error");
    throw new Error("Something went wrong when getting the catalog pricing");
  }
  const { body } = await res.json();

  return body.data.map((recruitPriceDb: RecruitPriceDb) =>
    formatRecruitPrice(recruitPriceDb)
  );
};

export const useRecruitCatalogPrices = () => {
  const [prices, setPrices] = useState<RecruitPrice[]>();

  useEffect(() => {
    const get = async () => {
      setPrices(await getRecruitCatalogPrices());
    };
    get();
  }, []);

  if (typeof prices == "undefined") {
    return { isLoaded: false, prices: undefined };
  } else {
    return { isLoaded: true, prices };
  }
};

const formatRecruitPrice = (data: RecruitPriceDb): RecruitPrice => ({
  planName: data.plan_name,
  teamSeats: data.team_seats,
  interviewFlowsCap: data.interview_flows_cap || Infinity, // if this is null, it means there is no limit
  hasTalentPoolAccess: data.has_talent_pool_access,
  hasIntegrationsAccess: data.has_integrations_access,
  hasVideoInterviewsAccess: data.has_video_interviews_access,
  hasWhiteLabelAccess: data.has_white_label_access,
  voicesIncluded: data.voices_included,
  usageBasedUnitAmountDecimal: data.usage_based_unit_amount_decimal,
  usageBasedRecurringInterval: data.usage_based_recurring_interval,
  includedInterviewsPerRecurringInterval:
    data.included_interviews_per_recurring_interval,
  planUnitAmountDecimal: data.plan_unit_amount_decimal,
  planRecurringInterval: data.plan_recurring_interval,
  trialPeriodDays: data.trial_period_days,
});

const getRecruiterPlanDetails = async (): Promise<{
  data?: RecruitPlan | null;
  error?: string;
}> => {
  const res = await fetchAuthWrapper.get("/be-api/get-recruiter-plan-details");
  if (res.status !== 200) {
    logger("Error calling get-recruiter-plan-details", "error");
    return { error: "Something unexpected happened" };
  }
  const { body } = await res.json();

  if (!body) return { data: null };

  return {
    data: {
      ...formatRecruitPrice(body),
      couponDurationInMonths: body.coupon_duration_in_months,
      couponAmountOff: body.coupon_amount_off,
      couponPercentOff: body.coupon_percent_off,
      trialEnd: body.trial_end,
      cancelAt: body.cancel_at,
      hasPaymentMethod: body.has_payment_method,
      interviewUsageForCurrentInterval:
        body.interview_usage_for_current_interval,
    },
  };
};

export const useRecruitPlanRealtime = ():
  | {
      isLoaded: true;
      plan: RecruitPlan | null;
    }
  | {
      isLoaded: false;
      plan: undefined;
    } => {
  const plan = useSelector(selectRecruitPlan);
  const { team } = useRecruiterTeam();
  const recruitPlanChannelName = "db-recruit-plan";
  const isChannelHealthy = useRealtimeChannelHealth(
    recruitPlanChannelName,
    !!team?.id
  );

  useEffect(() => {
    // only sub if there is a team id
    if (!team?.id || isChannelHealthy) return;

    const channel = supabase.channel(recruitPlanChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "recruiting_team",
        filter: `id=eq.${team.id}`,
      },
      async () => {
        const { data } = await getRecruiterPlanDetails();
        store.dispatch(addPlan(data || null));
      }
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        const { data } = await getRecruiterPlanDetails();
        store.dispatch(addPlan(data || null));
      }
    });
  }, [team?.id, isChannelHealthy]);

  // we check that the value is not undefined, no plan should return null
  if (typeof plan !== "undefined") {
    return { plan, isLoaded: true };
  } else {
    return { plan: undefined, isLoaded: false };
  }
};
