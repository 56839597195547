import { createSlice } from "@reduxjs/toolkit";

import { InterviewOverview } from "apis/recruit";

import { Action, State } from "./store";

export const interviewsSlice = createSlice({
  name: "interviews",
  initialState: {
    counters: undefined as InterviewOverview | undefined,
  },
  reducers: {
    addCounters: (state, action: Action<InterviewOverview>) => {
      state.counters = action.payload;
    },
  },
});

export const { addCounters } = interviewsSlice.actions;

export const selectInterviewsCounters = (
  state: State
): InterviewOverview | undefined => {
  return state.interviews.counters;
};

export default interviewsSlice.reducer;
