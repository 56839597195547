import { getSession } from "./authentication";

// Example usage
// let response = await fetchAuthWrapper.get(
//     "/be-api/devtool/addresses?" +
//       new URLSearchParams({ id: "arsham@ribbon.ai" })
//   );
//
// request -> /be-api/devtool/addresses
// Nextjs middleware adds JWT to header and rewrites to our backend
// -> our-backend.com/devtool/addresses
// backend uses JWT to authenticate and authorize request with supabase

export const fetchAuthWrapper = {
  get,
  post,
};

async function get(url: string) {
  const session = await getSession();

  if (!session) {
    throw new Error("No authenticated session");
  }

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.access_token}`,
    },
  };
  return fetch(url, requestOptions);
}

async function post(url: string, body: FormData | object) {
  const session = await getSession();

  if (!session) {
    throw new Error("No authenticated session");
  }

  const headers = new Headers({
    Authorization: `Bearer ${session.access_token}`,
  });

  if (!(body instanceof FormData)) {
    // do not set content-type, for formdata let the browser do it, the content-type needs to include boundaries which are better set by the browser
    // however set it for other content type
    headers.set("Content-Type", "application/json");
  }

  const requestOptions = {
    method: "POST",
    headers,
    body: body instanceof FormData ? body : JSON.stringify(body),
  };
  return fetch(url, requestOptions);
}
